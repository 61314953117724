import React from "react";
import styles from "./Title.module.css";

class Title extends React.Component {
  render() {
    const { children, nowrap } = this.props;
    return (
      <h1 className={`${styles.title} ${nowrap && styles.titleNowrap}`}>
        {children}
      </h1>
    );
  }
}

export default Title;
