import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import Modal from "react-modal";
import mousetrap from "mousetrap";
import Title from "../components/Title";
import Section from "../components/Section";
import Right from "../icons/right.svg";
import Left from "../icons/left.svg";
import styles from "./gallery.module.css";
import breakpoints from "../style/Breakpoints";

export default function Gallery(props) {
  const [activePhoto, setActivePhoto] = useState(-1);

  const { pagesJson } = props.data;

  const toLeft = (e) => {
    if (e) {
      e.stopPropagation();
    }
    if (activePhoto > 0) {
      setActivePhoto(activePhoto - 1);
    }
  };
  const toRight = (e) => {
    if (e) {
      e.stopPropagation();
    }
    if (activePhoto < pagesJson.gallery.length - 1) {
      setActivePhoto(activePhoto + 1);
    }
  };

  useEffect(() => {
    mousetrap.bind(`left`, (e) => toLeft());
    mousetrap.bind(`right`, (e) => toRight());

    return () => {
      mousetrap.unbind(`left`);
      mousetrap.unbind(`right`);
    };
  });

  return (
    <>
      <Helmet>
        <title>Photo Gallery</title>
        <meta property="og:title" content="Lunsford Ridge Farm | Gallery" />
        <meta
          property="og:url"
          content="https://www.lunsfordridgefarm.com/gallery"
        />
      </Helmet>
      <Section>
        <Title>Photo Gallery</Title>
        <div className={styles.wrapper}>
          {pagesJson.gallery.map((img, i) => {
            img.childImageSharp.fluid.sizes = `(min-width: ${breakpoints.large}) 26vw, (min-width: ${breakpoints.medium}) 33vw,(min-width: ${breakpoints.small}) 50vw, 98vw`;
            img.childImageSharp.modal.sizes = "90vw";

            return (
              <button
                onClick={() => setActivePhoto(i)}
                className={styles.imagewrap}
                key={img.childImageSharp.id}
                type="button"
              >
                <Img
                  className={styles.image}
                  loading="lazy"
                  placeholderClassName={styles.based}
                  fluid={img.childImageSharp.fluid}
                  alt=""
                />
              </button>
            );
          })}
        </div>
        <Modal
          onRequestClose={() => setActivePhoto(-1)}
          className={styles.modal}
          overlayClassName={styles.overlay}
          isOpen={activePhoto >= 0}
        >
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            aria-hidden="true"
            onClick={() => setActivePhoto(-1)}
            className={styles.modalcontent}
          >
            {activePhoto > 0 && (
              <Left onClick={(e) => toLeft(e)} className={styles.left} />
            )}
            {activePhoto >= 0 && (
              <Img
                className={styles.modalimg}
                onClick={(e) => setActivePhoto(-1)}
                fadeIn={false}
                loading="lazy"
                objectFit="contain"
                fluid={pagesJson.gallery[activePhoto].childImageSharp.modal}
                alt=""
              />
            )}
            {activePhoto < pagesJson.gallery.length - 1 && (
              <Right onClick={(e) => toRight(e)} className={styles.right} />
            )}
          </div>
        </Modal>
      </Section>
    </>
  );
}

export const pageQuery = graphql`
  query {
    pagesJson(pageName: { eq: "about" }) {
      gallery {
        childImageSharp {
          fluid(quality: 100) {
            presentationWidth
            presentationHeight
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
          modal: fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  }
`;
