import React from "react";
import styles from "./Section.module.css";

class Section extends React.Component {
  render() {
    const { children, last, full, grey, extraClasses, id } = this.props;
    const classes = [styles.section];
    if (full) {
      classes.push(styles.section__full);
    }
    if (last) {
      classes.push(styles.section__last);
    }
    if (grey) {
      classes.push(styles.section__grey);
    }
    if (extraClasses) {
      classes.push(...extraClasses);
    }
    return (
      <section id={id || ""} className={classes.join(" ")}>
        {children}
      </section>
    );
  }
}

export default Section;
